import { Link, useParams } from "react-router-dom";
import { capitalizeString } from "../utils/utils";

export const Project = () => {
  let { id } = useParams();

  return (
    <div>
      <h2>
        Welcome to the {id.split("-").map(capitalizeString).join(" ")} course!
      </h2>

      <p>This is a great course. You're gonna love it!</p>

      <Link to="/project">See all courses</Link>
    </div>
  );
}


