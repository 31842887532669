import { Link } from "react-router-dom";

export const Service = () => {
  return (
    <div className="bg-[#D7B49E] min-h-screen flex flex-col items-center justify-center py-16">
      <h1 className="text-5xl lg:text-7xl font-bold mb-12 text-white">User Reviews</h1>

      <div className="w-full max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 px-4">
        <div className="bg-white rounded-lg p-8 shadow-lg">
          <p className="text-lg text-black font-semibold">"This app made my home redesign process so easy and fun. The style quiz nailed my preferences!"</p>
          <p className="mt-4 text-gray-600">- Emily R., Interior Designer</p>
        </div>

        <div className="bg-white rounded-lg p-8 shadow-lg">
          <p className="text-lg text-black font-semibold">"I always thought I didn't have the eye for design, but this app showed me otherwise. Highly recommend!"</p>
          <p className="mt-4 text-gray-600">- John D., User</p>
        </div>

        <div className="bg-white rounded-lg p-8 shadow-lg">
          <p className="text-lg text-black font-semibold">"The integration with Pinterest was a game changer for me. It brought all my ideas to life."</p>
          <p className="mt-4 text-gray-600">- Sarah K., User</p>
        </div>

        <div className="bg-white rounded-lg p-8 shadow-lg">
          <p className="text-lg text-black font-semibold">"The step-by-step guide was perfect. It’s like having a designer right in your pocket!"</p>
          <p className="mt-4 text-gray-600">- Mike W., Interior Designer</p>
        </div>
      </div>

      <div className="mt-12">
        <a
          href="/submit-review"
          className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-white rounded-lg bg-[#704F4F] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
        >
          Submit Your Review
        </a>
      </div>
    </div>
  );
}


