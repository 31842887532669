import { Navigation } from '../components/Navigation';
import { Footer } from '../components/Footer';
import { Outlet } from "react-router-dom";
import './layout.css';

export const Layout = () => {
  return (
    <div className="App">
      <Navigation />
        <main class="pt-16">
          <Outlet />
        </main>
      <Footer />
    </div>
  );
}

