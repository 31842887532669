import styles from './Home.module.css';
import phone1 from '../assets/phone1.webp';
import phone2 from '../assets/phone2.webp';
import pinterest1 from '../assets/pinterest-before.webp';
import pinterest2 from '../assets/pinterest-after.webp';
import whiteArrow from '../assets/white-arrow.webp';
import pintrest from '../assets/pintrest.webp';
import bg2 from '../assets/bg2.webp';

export const Home = () => {
  return (
    <>
      <section className="bg-cover bg-no-repeat bg-[url('https://media-public.canva.com/MADGyScvRkY/4/screen_2x.png')] bg-blend-multiply h-screen flex items-center">
        <div className="px-4 mx-auto w-full max-w-screen-xl text-center py-24 lg:py-56">
          <p className="mb-4 text-3xl font-normal leading-10 text-black text-justify w-1/3">Many people think they either don't have enough money or simply don't have the right eye for interior design.
            <span className="mb-8 text-3xl text-black font-bold text-left"> We think both are nonsense.</span>
          </p>
          <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
            <a href="/getting-started" className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-smokey-100 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
              Get started
              <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
              </svg>
            </a>
            <a href="/learn-more" class="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-black rounded-lg border border-black hover:bg-gray-100 focus:ring-4 focus:ring-gray-400">
              Learn more
            </a>
          </div>
        </div>
      </section>
      <section className="flex h-screen">
          <div className="w-1/2 bg-[#D7B49E] flex items-center justify-center p-10">
              <div className="max-w-md">
                  <p className="mb-4 text-3xl font-normal leading-10 text-black text-justify">
                      The app playfully captures the user's style and <strong>guides them step by step</strong> through a <strong>customized redesign journey.</strong>
                  </p>
              </div>
          </div>

          <div className={`w-1/2 bg-cover`} style={{ backgroundImage: `url(${bg2})` }}>
          </div>
      </section>
      <section className={`flex h-screen ${styles.gradient}`}>
        <div class="lg:w-1/2 flex flex-col lg:flex-row items-center justify-center p-4 lg:p-8">
          <div class="w-full lg:w-1/2">
            <img src={phone1} alt="Mobile Screenshot 2" class="h-1/2 mt-8 rounded-lg p-5" />
          </div>
          <div class="w-full lg:w-1/2">
            <img src={phone2} alt="Mobile Screenshot 2" class="h-1/2 mt-8 rounded-lg p-5" />
          </div>
        </div>

        <div class="lg:w-1/2 flex flex-col justify-center text-white p-8">
          <h1 class="text-5xl lg:text-7xl font-bold mb-4">App Features</h1>
          <h2 class="text-2xl lg:text-4xl mb-6">STYLE QUIZ</h2>
          <p class="text-lg lg:text-xl">
            <strong class="font-semibold">Interactive</strong> quiz to determine the user's preferred design style.
          </p>
        </div>
      </section>
      <section className={`flex h-screen ${styles.gradient2}`}>
        <div class="lg:w-1/2 flex flex-col justify-center text-white p-8">
          <h2 class="text-2xl lg:text-4xl mb-6 font-normal">Integration with Pintrest</h2>
          <p class="text-lg lg:text-xl font-normal">
            Allow the users to link their Pinterest boards for tailored design ideas.
          </p>
        </div>
        <div class="lg:w-1/2 flex flex-col lg:flex-row items-center justify-center p-4 lg:p-8">
          <div class="w-full lg:w-1/2">
            <img src={pinterest1} alt="Mobile Screenshot 2" class="h-1/2 mt-8 rounded-lg p-5" />
            <img src={whiteArrow} alt="Mobile Screenshot 2" class={`h-1/2 mt-8 rounded-lg float-right ${styles.whiteArrow}`} />
          </div>
          <div class="w-full lg:w-1/2">
            <img src={pintrest} alt="Mobile Screenshot 2" class={`h-1/2 mt-8 rounded-lg`} />
            <img src={pinterest2} alt="Mobile Screenshot 2" class="h-1/2 mt-8 rounded-lg white-arrow p-5" />
          </div>
        </div>

      </section>
      <section className={`flex h-screen ${styles.gradient}`}>
          <div className="w-5/6">
          </div>
      </section>
      <section className={`flex h-screen ${styles.gradient2}`}>
          <div className="w-5/6">
          </div>
      </section>
      <section className={`flex h-screen ${styles.gradient}`}>
          <div className="w-5/6">
          </div>
      </section>
      <section className={`flex h-screen ${styles.gradient2}`}>
          <div className="w-5/6">
          </div>
      </section>
      <section className={`flex h-screen ${styles.gradient}`}>
          <div className="w-5/6">
          </div>
      </section>
      <section className={`flex h-screen ${styles.gradient2}`}>
          <div className="w-5/6">
          </div>
      </section>
      <section className={`flex h-screen ${styles.gradient}`}>
          <div className="w-5/6">
          </div>
      </section>
    </>
  );
}
