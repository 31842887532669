import hicham from '../assets/hicham.webp';
import robin from '../assets/robin.png';
import nina from '../assets/nina.png';

export const Team = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base text-gray-600 font-semibold tracking-wide uppercase">Monie Studio</h2>
          <h1 className="mt-2 text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
            Meet The Team
          </h1>
          <p className="mt-4 text-lg leading-6 text-gray-600">
            We are a diverse team of creative tech enthusiasts with backgrounds in design and movie
            industry as well as B2B automation technologies. We believe in digitization, automation
            and optimization and want to make corresponding processes accessible to the entire SME sector.
            Therefore, we're using AI-based breakthrough technology to help people manage their workflows
            in a new & modern way of working.
          </p>
        </div>

        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="mx-auto h-40 w-40 overflow-hidden rounded-full">
                <img
                  className="object-cover h-full w-full"
                  src={nina}
                  alt="Nina Damm"
                />
              </div>
              <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900">Nina Damm</h3>
              <p className="text-secondary-100">Design & GTM</p>
            </div>
            <div className="text-center">
              <div className="mx-auto h-40 w-40 overflow-hidden rounded-full">
                <img
                  className="object-cover h-full w-full"
                  src={hicham}
                  alt="Hicham Naoufal"
                />
              </div>
              <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900">Hicham Naoufal</h3>
              <p className="text-secondary-100">Architecture & Engineering</p>
            </div>
            <div className="text-center">
              <div className="mx-auto h-40 w-40 overflow-hidden rounded-full">
                <img
                  className="object-cover h-full w-full"
                  src={robin}
                  alt="Robin Kiwitt"
                />
              </div>
              <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900">Robin Kiwitt</h3>
              <p className="text-secondary-100">Product & GTM</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


