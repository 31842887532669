import './App.css';
import { routeConfig } from './config/routes';

import { useRoutes } from "react-router-dom";

function App() {
  return useRoutes(routeConfig);
}

export default App;

