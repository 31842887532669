import { Home } from "../pages/Home";
import { Layout } from "../pages/Layout";

import { Contact } from "../pages/Contact";
import { Team } from "../pages/Team";
import { Service } from "../pages/Service";
import { Login } from "../pages/Login";

import { Projects } from "../pages/Projects";
import { ProjectsIndex } from "../pages/ProjectsIndex";
import { Project } from "../pages/Project";
import { NoMatch } from "../pages/NoMatch";

export const routeConfig = [
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "/projects",
        element: <Projects />,
        children: [
          { index: true, element: <ProjectsIndex /> },
          { path: "/projects/:id", element: <Project /> },
        ],
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/team",
        element: <Team />,
      },
      {
        path: "/service",
        element: <Service />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      { path: "*", element: <NoMatch /> },
    ],
  }
];

